import React, { Component } from 'react';
import Navigation from './components/navigation/Navigation';


import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">

        <header>
          <Navigation></Navigation>
        </header>
      </div>
    );
  }
}
export default App;